import React from 'react';
import DashBoard from '../components/DashBoard/DashBoard';

function Dashboard(props) {
    return (
        <div>
            <DashBoard/>
        </div>
    );
}

export default Dashboard;